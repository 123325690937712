import Vue, { CreateElement, VNode } from 'vue';
import sanitize, { defaults as sanitizeDefaults } from 'sanitize-html';
import { addInnerHtml } from '../../assets/js/utilities/addInnerHtml';
import { ClsComponentMixin } from '../mixins/cls-component-mixin';
import { HtmlFragmentProps } from './HtmlFragment.props';

// prettier-ignore
const DEFAULT_WHITELIST_WRAPPING_TAGS = [
	'div', 'span', 'p', 'li', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6',
];

export default Vue.extend({
	name: 'HtmlFragment',
	mixins: [ClsComponentMixin],
	props: HtmlFragmentProps,
	computed: {
		sanitizedHtml(): string {
			if (!this.html) return '';

			return sanitize(this.html, {
				allowedTags: [...sanitizeDefaults.allowedTags, ...DEFAULT_WHITELIST_WRAPPING_TAGS, 'img'],
				allowedAttributes: false,
			});
		},
	},
	methods: {
		getWrappingTag(): string | undefined {
			if (
				!this.wrappingTag ||
				(!DEFAULT_WHITELIST_WRAPPING_TAGS.includes(this.wrappingTag) &&
					!this.whitelistTags?.includes(this.wrappingTag))
			) {
				return 'span';
			}

			return this.wrappingTag;
		},
	},
	render(h: CreateElement): VNode {
		return h(this.getWrappingTag(), this.sanitizedHtml ? addInnerHtml(this.sanitizedHtml) : {});
	},
});
